import "./howWeDoIt.css";

const ArrowOver = () => {
  return (
    <svg
      className="arrowSvg1"
      width="257"
      height="36"
      viewBox="0 0 257 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 35C1 35 121.7 -41.5 256 35"
        stroke="#CF7D1C"
        strokeWidth="1.5"
        strokeDasharray="5 5"
      />
    </svg>
  );
};

const ArrowUnder = () => {
  return (
    <svg
      className="arrowSvg2"
      width="243"
      height="35"
      viewBox="0 0 243 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1C1 1 115.073 75.25 242 1"
        stroke="#CF7D1C"
        strokeWidth="1.5"
        strokeDasharray="5 5"
      />
    </svg>
  );
};

const HowWeDoIt = () => {
  return (
    <section className="servicePage" id="howWeDoIt">
      <h1>How we work</h1>
      <p className="serviceDescription">
        We offer a variety of testing services, including functional testing,
        regression testing, performance testing, security testing, and more. We
        work closely with our clients to understand their specific testing needs
        and develop customized testing plans that align with their objectives
        and timelines.
      </p>

      <div className="steps">
        <div className="step">
          <div className="stepNumber">1</div>
          <h4>Requirement Gathering</h4>
          <p>
            Collaborate with stakeholders to gather project information and
            clarify scope.
          </p>
        </div>

        <ArrowOver />

        <div className="step">
          <div className="stepNumber">2</div>
          <h4>Planning and Design</h4>
          <p>
            Our focus is on designing software architecture, UI, and UX for a
            seamless application experience.
          </p>
        </div>

        <ArrowUnder />

        <div className="step">
          <div className="stepNumber">3</div>
          <h4>Development</h4>
          <p>
            We employ an iterative and agile development approach, delivering
            frequent updates and soliciting client feedback for alignment.
          </p>
        </div>
      </div>
      <div className="steps">
        <div className="step">
          <div className="stepNumber">4</div>
          <h4>Testing</h4>
          <p>
            Dedicated QA team performs rigorous testing with various
            methodologies for quality assurance.
          </p>
        </div>

        <ArrowOver />

        <div className="step">
          <div className="stepNumber">5</div>
          <h4>Deployment and Integration</h4>
          <p>
            Experienced deployment team integrates software smoothly into client
            infrastructure, minimizing disruptions.
          </p>
        </div>

        <ArrowUnder />

        <div className="step">
          <div className="stepNumber">6</div>
          <h4>Maintenance and Support</h4>
          <p>
            We offer ongoing maintenance, support, bug fixes, updates, and
            enhancements for optimal software performance.
          </p>
        </div>
      </div>
    </section>
  );
};

export default HowWeDoIt;
