import "./App.css";
//REACT IMPORTS
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

//COMPONENTS
import HomePage from "./pages/HomePage";
import Blog from "./sections/blog/blog";
import NavBar from "./components/Navbar/nav-bar";
import Footer from "./components/Footer/footer";

function App() {
  return (
    <Router>
      <NavBar/>
      <Routes>
        <Route path="/" element={<HomePage />}/>
        <Route path="/blog" element={<Blog/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
