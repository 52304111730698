import React from "react";

const BlogSection = (props) => {
  return (
    <>
      <h2 className="blog-section">{props.heading}</h2>
      {props.paragraphs.map((paragraph, index) => {
        return (
          <p key={index} className="blog-content">
            {paragraph}
          </p>
        );
      })}
    </>
  );
};

export default BlogSection;
