import "./blog.css";
import Share from "../../components/Share/Share";
import BlogImage from "../../components/Blogs/BlogImage";
import BlogSection from "../../components/Blogs/BlogSection";
import DUMMY_BLOG from "../../blogPosts/templateBlog.json";

const Blog = () => {
  return (
    <div className="blog">
      <Share />
      <BlogImage src="logo-white.png" alt="Company logo" />
      <article className="blog-wrapper">
        <h1 className="blog-title">{DUMMY_BLOG.title}</h1>
        <p className="blog-content">{DUMMY_BLOG.description}</p>
        {DUMMY_BLOG.sections.map((section, index) => {
          return (
            <BlogSection
              key={index}
              heading={section.heading}
              paragraphs={section.paragraphs}
            />
          );
        })}
      </article>
    </div>
  );
};

export default Blog;
