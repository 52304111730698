import "./banner.css";

const Banner = () => {
  return (
    <section className="banner-section">
      <div className="banner">
        <div className="outer-banner">
          <div className="inner-banner layout-banner">
            <p>Need the best software development services? Contact us now!</p>
            <svg
              className="loop banner-opacity"
              width="215"
              height="90"
              viewBox="0 0 215 90"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M169.91 11.2599C177.073 6.8625 185.145 4.11888 193.237 3.34359C200.135 2.66815 207.302 3.41657 214.127 5.48272L214.362 2.74149C207.266 0.69256 200.042 -0.0203275 192.983 0.654755C184.483 1.47594 176.023 4.34796 168.505 8.95744C160.987 13.5669 154.607 19.8229 150.03 27.0415C148.739 29.0726 147.603 31.1709 146.611 33.322C134.881 36.803 124.444 43.2638 114.873 49.8258C112.542 51.4176 110.192 53.0752 107.927 54.6728C99.6323 60.4954 91.0649 66.5102 81.8626 70.9754C71.4803 76.0131 59.8489 79.1254 48.2227 79.9752C36.2712 80.851 24.677 79.4406 13.761 75.7714C9.28548 74.2567 4.93678 72.3622 0.818356 70.1372L0.569872 73.0389C4.56721 75.1054 8.65773 76.87 12.9117 78.2984C19.5604 80.538 26.4672 81.9646 33.5252 82.569C38.4177 82.988 43.398 83.0103 48.4249 82.6461C60.3765 81.7703 72.3523 78.5663 83.0437 73.3799C92.4289 68.8226 101.096 62.749 109.463 56.8653C111.728 55.2677 114.077 53.6234 116.393 52.0438C125.205 46.0095 134.721 40.076 145.255 36.5734C142.074 44.9486 141.224 53.7661 142.956 61.8481C144.234 67.8439 147.036 73.4853 151.052 78.1665C155.214 83.0219 160.411 86.4977 166.095 88.2101C171.909 89.9742 178.379 89.7336 184.327 87.5759C190.429 85.3505 195.371 81.2613 198.267 76.0674C201.464 70.3336 202.189 63.2836 200.302 56.2254C198.517 49.5531 194.47 43.3471 189.184 39.1903C184.062 35.1686 177.835 32.561 170.713 31.4527C164.268 30.4429 157.281 30.747 150.507 32.3221C150.356 32.363 150.205 32.3905 150.053 32.4314C150.733 31.1022 151.491 29.7932 152.301 28.5156C156.662 21.6153 162.747 15.6573 169.91 11.2599ZM151.106 34.9192C157.55 33.4236 164.185 33.1432 170.298 34.0977C176.968 35.1403 182.766 37.563 187.523 41.2974C192.355 45.0922 196.069 50.7847 197.699 56.905C199.401 63.2873 198.764 69.631 195.915 74.7481C190.664 84.1853 177.893 88.9646 166.854 85.635C161.669 84.073 156.906 80.8769 153.074 76.4135C149.352 72.0673 146.756 66.8476 145.562 61.2901C143.8 53.0976 144.898 44.0455 148.545 35.5755C149.402 35.3255 150.254 35.1157 151.106 34.9192Z"
                fill="white"
              />
            </svg>
            <svg
              className="arrow banner-opacity"
              width="35"
              height="28"
              viewBox="0 0 35 28"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M34.1684 23.6593L8.59687 0.316007L8.22493 10.5936L26.1272 20.3531L8.08754 14.3881L8.08716 14.3979L8.08689 14.3861L0.193055 16.9892L7.8791 20.1495L7.6148 27.4507L34.1684 23.6593Z"
                fill="white"
              />
            </svg>
            <a href="#contact_us" className="contact-us--btn">
              <svg
                className="line-1 banner-opacity"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="2.60352"
                  y="-0.591797"
                  width="21.2665"
                  height="3.54442"
                  rx="1.77221"
                  transform="rotate(42.1773 2.60352 -0.591797)"
                  fill="white"
                />
              </svg>
              <svg
                className="line-2 banner-opacity"
                width="4"
                height="23"
                viewBox="0 0 4 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="3.77344"
                  y="0.80957"
                  width="22.1442"
                  height="2.88837"
                  rx="1.44419"
                  transform="rotate(90 3.77344 0.80957)"
                  fill="white"
                />
              </svg>
              <svg
                className="line-3 banner-opacity"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="-0.0253906"
                  y="13.6875"
                  width="21.2665"
                  height="3.54442"
                  rx="1.77221"
                  transform="rotate(-42.18 -0.0253906 13.6875)"
                  fill="white"
                />
              </svg>
              Contact us
              <svg
                className="line-4 banner-opacity"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="21.5071"
                  height="3.58451"
                  rx="1.79226"
                  transform="matrix(0.741071 -0.671427 -0.671427 -0.741071 2.24609 16.6758)"
                  fill="white"
                />
              </svg>
              <svg
                className="line-5 banner-opacity"
                width="4"
                height="24"
                viewBox="0 0 4 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="22.3947"
                  height="2.92105"
                  rx="1.46052"
                  transform="matrix(0 -1 -1 0 3.80273 23.3594)"
                  fill="white"
                />
              </svg>
              <svg
                className="line-6 banner-opacity"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  width="21.5071"
                  height="3.58451"
                  rx="1.79226"
                  transform="matrix(0.741039 0.671462 0.671462 -0.741039 0.164062 2.23438)"
                  fill="white"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
