import "./contactUs.css";
import emailjs from "@emailjs/browser";
import React, { useRef, useState } from "react";

const ContactUs = () => {
  const form = useRef();

  //Local State
  const [messageSent, setMessageSent] = useState("SUBMIT");

  //Method to send email
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_otnnsz4",
        "template_y7qf169",
        form.current,
        "yfVnptrpAtg26ijh-"
      )
      .then(
        (result) => {
          setMessageSent("Message sent, will reply asap!");
        },
        (error) => {
          setMessageSent("There was an error please try again");
        }
      );
  }

  return (
    <section className="section-contact_us" id="contact_us">
      <div className="card-contact_us">
        <h1 className="heading-contact_us">Contact us now</h1>
        <form className="form-contact_us" ref={form} onSubmit={sendEmail}>
          <div className="inputs-contact_us">
            <label htmlFor="full-name">Full Name</label>
            <input
              type="text"
              id="full-name"
              name="full_name"
              placeholder="Enter Name"
              required
            />
            <label htmlFor="phone-number">Phone Number</label>
            <input
              type="tel"
              id="phone-number"
              name="phone_number"
              placeholder="Phone No"
            />
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email_address"
              placeholder="Email Address"
              required
            />
            <label htmlFor="message">Message</label>
            <textarea
              type="text"
              id="message"
              name="message"
              placeholder="Enter your message here"
            />
          </div>
          <button type="submit" className="button-contact_us">
            {messageSent}
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
