import Carousel from "../../components/Carousel/carousel";
import "./testimonialPage.css";
const TestimonialPage = () => {
  return (
    <section className="testimonial-section" id="testimonial-section">
      <h1 className="testimonial-heading">
        Our services will help your team improve
      </h1>
      <h2 className="testimonial-subheading">
        A professional development team at your disposal
      </h2>
      <Carousel />
    </section>
  );
};

export default TestimonialPage;
