import "./BlogImage.css";

const BlogImage = (props) => {
  return (
    <div className="blog-image-container">
      <img src={props.src} className="blog-image" alt={props.alt} />
    </div>
  );
};

export default BlogImage;
