import Cards from "./cards";
import "./priceCards.css";

const PriceCards = () => {
  return (
    <section className="price-card-section">
      <div className="price-card-title">Pricing</div>
      <div className="price-card-container">
        <Cards
          title="QA Engineer as Flex Support"
          price="25$"
          priceInfo="per Hour"
          services={[
            "Manual Testing",
            "Test Case Management",
            "Test Script Execution",
            "Regression testing",
            "Bug Reporting",
          ]}
        />

        <Cards
          title="QA Engineer Team"
          price="3500$"
          priceInfo="per Month"
          services={[
            "Test Planning and Strategy",
            "Functional Testing",
            "User Acceptance Testing",
            "Automated Testing",
            "Performance Testing",
            "Test Environment Set Up",
            "Documentation and Reporting",
          ]}
        />
        {/* <Cards
          title="QA Engineer Enterprise"
          priceInfo="Contact us!"
          services={[
            "Manual Testing",
            "Test Case Management",
            "Test Script Execution",
            "Regression testing",
            "Bug Reporting",
          ]}
        /> */}
      </div>
    </section>
  );
};

export default PriceCards;
