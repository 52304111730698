import "./carousel.css";

const Carousel = () => (
  <div className="carousel">
    <div className="carousel-card">
      <img src="./carousel3.png" alt="Slide 1" className="card-image" />
      <h3 className="card-heading">
        We offer the best software testing services
      </h3>
      <p className="card-description">
        Work with qualified professionals that understand your needs
      </p>
      <a href="#readMore" className="card-link">
        Read more
      </a>
    </div>
    <div className="carousel-card">
      <img src="./carousel2.png" alt="Slide 1" className="card-image" />
      <h3 className="card-heading">
        Established testing services for various teams
      </h3>
      <p className="card-description">
        Give a QA resource to your teams and see them improve
      </p>
      <a href="#readMore" className="card-link">
        Read more
      </a>
    </div>
    <div className="carousel-card">
      <img src="./carousel1.png" alt="Slide 1" className="card-image" />
      <h3 className="card-heading">Delegate your testing needs to us</h3>
      <p className="card-description">
        We'll focus on testing so that your team can focus on building
      </p>
      <a href="#readMore" className="card-link">
        Read more
      </a>
    </div>
  </div>
);

export default Carousel;
