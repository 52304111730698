//COMPONENTS
import LandingPage from "../sections/landingPage/LandingPage";
import HowWeDoIt from "../sections/howWeDoIt/howWeDoIt";
import ContactUs from "../sections/contactUs/contactUs";
import Perks from "../sections/perks/perks";
import Banner from "../components/Banner/banner";
import OurService from "../sections/ourService/ourService";
import TestimonialPage from "../sections/testimonial/testimonialPage";
import PriceCards from "../components/cards/priceCards";

//REACT IMPORTS
import { useLocation } from "react-router";
import { useEffect } from "react";

const HomePage = () => {

  const location = useLocation();

  const handleScrollIntoView = (id) => {
     document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if(window.location.hash){
      handleScrollIntoView(window.location.hash.substring(1))
    }
  }, [location]);

  return (
    <>
      <LandingPage />
      <HowWeDoIt />
      <OurService />
      <Banner />
      <Perks />
      <TestimonialPage />
      <PriceCards />
      <ContactUs />
    </>
  );
};

export default HomePage;
