import "./perk.css";

const PerkIcon = () => {
  return (
    <div className="perk-icon">
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 9.5C0 4.25329 4.25329 0 9.5 0C14.7467 0 19 4.25329 19 9.5C19 14.7467 14.7467 19 9.5 19C4.25329 19 0 14.7467 0 9.5Z"
          fill="#CF7D1C"
        />
      </svg>
      <svg
        height="800px"
        width="800px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 17.837 17.837"
        className="perk-icon-checkmark"
      >
        <g>
          <path
            fill="#FFF"
            d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27
		c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0
		L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"
          />
        </g>
      </svg>
    </div>
  );
};

const Perks = () => {
  return (
    <section className="section-perks">
      <div className="perk-box">
        <div className="perk-details">
          <h1 className="perk-heading">
            Perks of choosing us for your software development
          </h1>
          <h2 className="perk-subheading">
            We offer self-managed QA and developer experts with professional
            experience and deep knowledge of the software development process.
          </h2>
          <ol className="perk-list">
            <li>
              <PerkIcon />
              Push more stable deployments
            </li>
            <li>
              <PerkIcon />
              Speed up development
            </li>
            <li>
              <PerkIcon />
              Catch bugs before they reach production
            </li>
            <li>
              <PerkIcon />
              Confirm that fixed bugs no longer plague you
            </li>
            <li>
              <PerkIcon />
              Build new features and ensure they work as intended
            </li>
            <li>
              <PerkIcon />
              Automate your QA process
            </li>
          </ol>
          <div className="btn-perk-container">
            <a href="#contact_us" className="btn-perk btn-perk-primary">
              Contact us
            </a>
            <a href="#contact_us" className="btn-perk btn-perk-secondary">
              Learn more
            </a>
          </div>
        </div>
        <img src="./perkImg.png" alt="" style={{ width: "100%" }} />
      </div>
    </section>
  );
};

export default Perks;
