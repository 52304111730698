import React from "react";
import "./LandingPage.css";

const LandingPage = () => {
  return (
    <div className="hero">
      <div className="hero__header">
        We develop and test software, providing you our best services
      </div>
      <div className="hero__description">
        We are Hook Technologies, specializing in developing top-quality
        software. With expertise and dedication, our engineers ensure the
        highest standards for your software.
      </div>
      <div className="hero__sub-header">
        <svg
          width="100%"
          height="15"
          viewBox="0 0 461 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 12C69.1419 5.20839 252.74 -4.29987 458 12"
            stroke="#CF7D1C"
            strokeWidth="5"
            strokeLinecap="round"
          />
        </svg>
      </div>
      <a href="/#contact_us">
        <button
          className="Button getStarted"
          style={{ display: "inline-block" }}
        >
          Contact us!
        </button>
      </a>
    </div>
  );
};

export default LandingPage;
