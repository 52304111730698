import React from "react";
import "./footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <section className="section-footer">
      <div className="footer-options">
        <img className="hook-logo" alt="" src="logo-white.png" />
        <nav></nav>
        <nav></nav>
        <nav>
          <p className="footer-heading">Resources</p>
          <ul className="footer-nav">
            <li>
              <Link className="footer-link" to="/blog">
                Blog
              </Link>
            </li>
            <li>
              <a className="footer-link" href="home">
                Pricing
              </a>
            </li>
          </ul>
        </nav>
        <nav>
          <p className="footer-heading">Follow Us</p>
          <ul className="footer-nav">
            <li>
              <a
                className="footer-link"
                href="https://www.linkedin.com/company/hook-technologies/about/"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="footer-end">
        <p className="">
          Copyright &copy; {new Date().getFullYear()} Hook Technologies. All
          rights reserved.
        </p>
        <div className="policy-box">
          <a className="footer-link" href="home">
            Privacy Policy
          </a>
          <span>&#124;</span>
          <a className="footer-link" href="home">
            Terms Of Service
          </a>
        </div>
      </div>
    </section>
  );
};

export default Footer;
