import "./cards.css";

const Cards = (props) => {
  return (
    <div className="outer-banner">
      <div className="inner-card layout-card">
        <div style={{ color: "whitesmoke" }} className="inside-card">
          <h2>{props.title}</h2>
          <div className="priceStarting">
            <p className="pr">Price Starting at</p>
            <h1>{props.price}</h1>
            <p>{props.priceInfo}</p>
          </div>
          <h2>Services</h2>
          <ul>
            {props.services.map((el) => {
              return <li key={el}>{el}</li>;
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Cards;
