import "./Share.css";

const Share = () => {
  return (
    <nav className="share-nav">
      <p className="share-title">Share</p>
      <ul className="share-options">
        <li className="share-icon-circle">
          <ion-icon className="share-icon" name="logo-facebook"></ion-icon>
        </li>
        <li className="share-icon-circle">
          <ion-icon className="share-icon" name="logo-twitter"></ion-icon>
        </li>
        <li className="share-icon-circle">
          <ion-icon className="share-icon" name="logo-linkedin"></ion-icon>
        </li>
      </ul>
    </nav>
  );
};

export default Share;
