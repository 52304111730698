import "./nav-bar.css";
import React, { useEffect, useState } from "react";
import logo from "../../assets/LogoFinal.png";
import { Link } from "react-router-dom";
import Hamburger from "hamburger-react";

const NavBar = () => {
  //Local state
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [navbarWidth, setNavbarWidth] = useState(0);

  //Keep track of the body width for the Navbar
  useEffect(() => {
    const parentContainerWidth = document.querySelector("body").clientWidth;
    setNavbarWidth(parentContainerWidth);
    // eslint-disable-next-line
  }, [window.innerWidth]);

  //keep track of the screen size
  const handleResize = () => {
    setScreenWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    // eslint-disable-next-line
  }, [window.innerWidth]);

  //Handle the Hamburger toggle

  const handleHamburger = (event) => {
    setHamburgerOpen(event);
  };

  //Scroll to top when clicking home
  const handleScrollTotop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <nav className="nav-container" style={{ width: `${navbarWidth}px` }}>
      <Link href="/" className=" logo navbar">
        <img src={logo} alt="Logo" />
      </Link>
      <div className="hamburger">
        {screenWidth < 1056 ? (
          <Hamburger
            onToggle={handleHamburger}
            toggled={hamburgerOpen}
            direction="right"
          />
        ) : (
          ""
        )}
      </div>

      <div
        className={
          hamburgerOpen
            ? "nav-principal-container"
            : "nav-principal-container-close"
        }
      >
        <ul className="nav-principal">
          <li>
            <Link
              to="/"
              className="navbar"
              onClick={() => {
                handleScrollTotop();
                handleHamburger(false);
              }}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/#howWeDoIt"
              className="navbar"
              onClick={() => {
                handleHamburger(false);
              }}
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/#testimonial-section"
              className="navbar"
              onClick={() => {
                handleHamburger(false);
              }}
            >
              About us
            </Link>
          </li>
          <li>
            <Link
              className="navbar"
              to="/#contact_us"
              onClick={() => {
                handleHamburger(false);
              }}
            >
              Contact us
            </Link>
          </li>
          <a className="getStarted" href="/#contact_us">
            <button href="home.html" className="Button getStarted">
              Get Started
            </button>
          </a>
        </ul>
        {hamburgerOpen && screenWidth < 1056 ? (
          <img src={logo} style={{ width: "200px" }} alt="logo" />
        ) : (
          ""
        )}
      </div>
    </nav>
  );
};

export default NavBar;
